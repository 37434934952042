import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Headings from '@components/Headings';
import Image from '@components/Image';
import mediaqueries from '@styles/media';

const authorContents = {
  writeUps: {
    slug: '/articles',
    title: 'Write-ups',
    excerpt: 'Personal blogs on JavaScript, React and Life',
    images: '',
  },
  talks: {
    slug: '/talks',
    title: 'Talks',
    excerpt: 'Speaker at: Conferences and meetups',
    images: '',
  },
};

const normaliseImage = (images) => ({
  full: images.full.fluid,
  regular: images.regular.fluid,
  narrow: images.narrow.fluid,
  seo: images.seo.fixed,
});

export const query = graphql`
  fragment GATSBY_FLUID on ImageSharpFluid {
    base64
    aspectRatio
    src
    srcSet
    srcWebp
    srcSetWebp
    sizes
  }
  query images {
    talks: file(relativePath: { eq: "images/talks.jpg" }) {
      full: childImageSharp {
        fluid(maxWidth: 944, quality: 100) {
          ...GATSBY_FLUID
        }
      }
      regular: childImageSharp {
        fluid(maxWidth: 653, quality: 100) {
          ...GATSBY_FLUID
        }
      }
      narrow: childImageSharp {
        fluid(maxWidth: 457, quality: 100) {
          ...GATSBY_FLUID
        }
      }
      seo: childImageSharp {
        fixed(width: 1200, quality: 100) {
          src
        }
      }
    }
    writeUps: file(relativePath: { eq: "images/write-ups.jpg" }) {
      full: childImageSharp {
        fluid(maxWidth: 944, quality: 100) {
          ...GATSBY_FLUID
        }
      }
      regular: childImageSharp {
        fluid(maxWidth: 653, quality: 100) {
          ...GATSBY_FLUID
        }
      }
      narrow: childImageSharp {
        fluid(maxWidth: 457, quality: 100) {
          ...GATSBY_FLUID
        }
      }
      seo: childImageSharp {
        fixed(width: 1200, quality: 100) {
          src
        }
      }
    }
  }
`;

function ArticlesList() {
  const results = useStaticQuery(query);
  authorContents.talks.images = normaliseImage(results.talks);
  authorContents.writeUps.images = normaliseImage(results.writeUps);
  return (
    <ArticlesListContainer>
      {Object.values(authorContents).map((authorContent) => {
        return (
          <List key={authorContent.slug}>
            <ListItem authorContent={authorContent} />
          </List>
        );
      })}
    </ArticlesListContainer>
  );
}

export default ArticlesList;

const ListItem = ({ authorContent }: ArticlesListItemProps) => {
  return (
    <ArticleLink to={authorContent.slug} data-a11y="false">
      <Item>
        <ImageContainer>
          <Image src={authorContent.images.regular} />
        </ImageContainer>
        <div>
          <Title dark>{authorContent.title}</Title>
          <Excerpt>{authorContent.excerpt}</Excerpt>
        </div>
      </Item>
    </ArticleLink>
  );
};

const limitToTwoLines = css`
  text-overflow: ellipsis;
  overflow-wrap: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;

  ${mediaqueries.phablet`
    -webkit-line-clamp: 3;
  `}
`;

const ArticlesListContainer = styled.div`
  transition: opacity 0.25s;
  p {
    display: -webkit-box;
  }

  h2 {
    margin-bottom: 10px;
  }
`;

const listItemRow = (p) => css`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 488px;
  grid-column-gap: 96px;
  grid-template-rows: 1;
  align-items: center;
  position: relative;
  margin-bottom: 50px;

  ${mediaqueries.desktop`
    grid-column-gap: 24px;
    grid-template-columns: 1fr 380px;
  `}

  ${mediaqueries.tablet`
    grid-template-columns: 1fr;
  `}

  @media (max-width: 540px) {
    background: ${p.theme.colors.card};
  }

  ${mediaqueries.phablet`
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  `}
`;

const List = styled.div`
  display: grid;
  grid-template-rows: '1fr';
`;

const Item = styled.div`
  ${listItemRow}
`;

const ImageContainer = styled.div`
  position: relative;
  height: '220px';
  box-shadow: 0 30px 60px -10px rgba(0, 0, 0, 0.3), 0 18px 36px -18px rgba(0, 0, 0, 0.33);
  margin-bottom: 0;
  transition: transform 0.3s var(--ease-out-quad), box-shadow 0.3s var(--ease-out-quad);

  & > div {
    height: 100%;
  }

  ${mediaqueries.tablet`
    height: 200px;
    margin-bottom: 35px;
  `}

  ${mediaqueries.phablet`
    overflow: hidden;
    margin-bottom: 0;
    box-shadow: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  `}
`;

const Title = styled(Headings.h2)`
  font-size: 21px;
  font-family: ${(p) => p.theme.fonts.serif};
  margin-bottom:  '10px';
  transition: color 0.3s ease-in-out;
  ${limitToTwoLines};

  ${mediaqueries.desktop`
    margin-bottom: 15px;
  `}

  ${mediaqueries.tablet`
    font-size: 24px;  
  `}

  ${mediaqueries.phablet`
    font-size: 22px;  
    padding: 30px 20px 0;
    margin-bottom: 10px;
    -webkit-line-clamp: 3;
  `}
`;

const Excerpt = styled.p`
  ${limitToTwoLines};
  font-size: 16px;
  margin-bottom: 10px;
  color: ${(p) => p.theme.colors.grey};
  display: 'box';
  max-width: '515px';

  ${mediaqueries.desktop`
    display: -webkit-box;
  `}

  ${mediaqueries.phablet`
    margin-bottom; 15px;
  `}

  ${mediaqueries.phablet`
    max-width: 100%;
    padding:  0 20px 30px;
    margin-bottom: 20px;
    -webkit-line-clamp: 3;
  `}
`;

const ArticleLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  z-index: 1;
  transition: transform 0.33s var(--ease-out-quart);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:hover ${ImageContainer}, &:focus ${ImageContainer} {
    transform: translateY(-1px);
    box-shadow: 0 50px 80px -20px rgba(0, 0, 0, 0.27), 0 30px 50px -30px rgba(0, 0, 0, 0.3);
  }

  &:hover h2,
  &:focus h2 {
    color: ${(p) => p.theme.colors.accent};
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -1.5%;
    top: -2%;
    width: 103%;
    height: 104%;
    border: 3px solid ${(p) => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.phablet`
    &:hover ${ImageContainer} {
      transform: none;
      box-shadow: initial;
    }

    &:active {
      transform: scale(0.97) translateY(3px);
    }
  `}
`;
